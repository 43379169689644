import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { Spinner } from "react-bootstrap";

function Flashcards({ translations }) {
    const [currentCard, setCurrentCard] = useState(0);

    const nextCard = () => {
        setCurrentCard((prevCard) => (prevCard + 1) % translations.length);
    };

    return (
        <div className="flashcards mt-8">
            {translations.length > 0 && (
                <div
                    className="card bg-white shadow-lg rounded-lg p-6 text-center cursor-pointer transition-transform transform hover:scale-105"
                    onClick={nextCard}
                >
                    <p className="text-xl font-semibold text-niceOrange">{translations[currentCard].swedish}</p>
                    <p className="text-lg text-gray-700 mt-2">{translations[currentCard].english}</p>
                </div>
            )}
        </div>
    );
}

function LanguageApp() {
    const initialFormState = { prompt: '', textLanguage: 'sv', translateLanguage: 'en', cefrLevel: 'A1', filename: '' };
    const [formData, setFormData] = useState(initialFormState);
    const [generatedLDF, setGeneratedLDF] = useState(null);
    const [flashcards, setFlashcards] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mp3Url, setMp3Url] = useState(null);

    async function generateLDFAndMP3() {
        setLoading(true);
        try {
            const modifiedPrompt = `${formData.prompt}.\nTranslate any words or phrases that may be outside of my CEFR language level, which is: ${formData.cefrLevel}`;
            const response = await axios.post(`${config.SERVER_URL}/generate-ldf`, { ...formData, prompt: modifiedPrompt });
            const ldfData = response.data[0];

            setGeneratedLDF(ldfData);
            setFlashcards(Object.entries(ldfData.translations).map(([swedish, english]) => ({ swedish, english })));

            // Prepare payload for MP3 creation
            const payload = { story: [ldfData] };

            const mp3Response = await axios.post(
                `${config.LANGUAGE_API}/create-mp3`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    responseType: 'arraybuffer'  // Expect binary response for file
                }
            );

            const blob = new Blob([mp3Response.data], { type: 'audio/mpeg' });
            const url = window.URL.createObjectURL(blob);
            setMp3Url(url);

            setError(null);
        } catch (error) {
            console.error('Error generating LDF and MP3:', error);
            setError('Error generating LDF and MP3');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Story-driven language learning</h1>
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-gray-700">Foreign Language (default: sv)</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Enter foreign language code"
                            value={formData.textLanguage}
                            onChange={e => setFormData({ ...formData, 'textLanguage': e.target.value })}
                            // disabled
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Native Language (default: en)</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Enter native language code"
                            value={formData.translateLanguage}
                            onChange={e => setFormData({ ...formData, 'translateLanguage': e.target.value })}
                            // disabled
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">CEFR Language Level</label>
                        <select
                            className="w-full px-4 py-2 border rounded-md"
                            value={formData.cefrLevel}
                            onChange={e => setFormData({ ...formData, 'cefrLevel': e.target.value })}
                        >
                            <option value="A1">A1</option>
                            <option value="A2">A2</option>
                            <option value="B1">B1</option>
                            <option value="B2">B2</option>
                            <option value="C1">C1</option>
                            <option value="C2">C2</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Filename</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border rounded-md"
                            placeholder="Enter filename without '.mp3' (optional)"
                            value={formData.filename}
                            onChange={e => setFormData({ ...formData, 'filename': e.target.value })}
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-gray-700">Prompt</label>
                    <input
                        type="text"
                        className="w-full px-4 py-2 border rounded-md"
                        placeholder="e.g. A short bio of Gustav Vasa"
                        value={formData.prompt}
                        onChange={e => setFormData({ ...formData, 'prompt': e.target.value })}
                    />
                </div>
                <div className="text-center">
                    <button className="p-3 px-6 pt-2 text-white rounded-full bg-niceOrange" onClick={generateLDFAndMP3} disabled={loading}>
                        {loading ? 'Loading...' : 'Generate Story'}
                    </button>
                </div>
            </div>
            {error && (
                <div className="mt-4">
                    <div className="bg-red-500 text-white p-2 rounded">
                        {error}
                    </div>
                </div>
            )}
            {loading && (
                <div className="flex justify-center mt-4">
                    <Spinner />
                </div>
            )}
            {flashcards.length > 0 && (
                <div className="mt-4">
                    <Flashcards translations={flashcards} />
                </div>
            )}
            {mp3Url && (
                <div className="mt-4">
                    <audio controls src={mp3Url} className="w-full mt-2"></audio>
                </div>
            )}
        </div>
    );
}

export default LanguageApp;
