import React, { useState, useEffect } from 'react';

const gates = [
    { type: 'AND', label: 'AND', operation: (a, b) => a && b },
    { type: 'OR', label: 'OR', operation: (a, b) => a || b },
    { type: 'NOT', label: 'NOT', operation: (a) => !a },
    { type: 'XOR', label: 'XOR', operation: (a, b) => a ^ b },
    { type: 'NAND', label: 'NAND', operation: (a, b) => !(a && b) },
    { type: 'NOR', label: 'NOR', operation: (a, b) => !(a || b) },
];

function LogicGateQuiz() {
    const [showInstructions, setShowInstructions] = useState(true);
    const [inputs, setInputs] = useState([0, 0]);
    const [desiredOutput, setDesiredOutput] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedGate, setSelectedGate] = useState(null);
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        if (!showInstructions) {
            generateNewPuzzle();
        }
    }, [showInstructions]);

    const generateNewPuzzle = () => {
        const newInputs = [Math.round(Math.random()), Math.round(Math.random())];
        setInputs(newInputs);
        const randomGate = gates[Math.floor(Math.random() * gates.length)];
        setDesiredOutput(randomGate.operation(...newInputs));
        setSelectedGate(null);
        setFeedback('');
    };

    const handleGateSelection = (gate) => {
        setSelectedGate(gate);
        const output = gate.operation(...inputs);
        if (output === desiredOutput) {
            setScore(score + 1);
            setFeedback('Correct! +1 point');
            setTimeout(generateNewPuzzle, 1500);
        } else {
            setFeedback('Incorrect. Try again!');
        }
    };

    const startQuiz = () => {
        setShowInstructions(false);
        setScore(0);
    };

    return (
        <div className="container mx-auto p-4">
            {showInstructions ? (
                <div className="flex flex-col justify-center items-center min-h-screen">
                    <h1 className="text-4xl font-bold mb-8 text-gray-800">Logic Gate Quiz</h1>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-2xl font-semibold mb-4">How to Play</h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>You'll be presented with two random input values (0 or 1) and a desired output.</li>
                            <li>Your task is to select the correct logic gate that will produce the desired output from the given inputs.</li>
                            <li>Click on the gate you think is correct.</li>
                            <li>If you're right, you'll earn a point and move to the next puzzle.</li>
                            <li>If you're wrong, you can try again.</li>
                            <li>Try to get as many points as you can!</li>
                        </ol>
                    </div>
                    <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        onClick={startQuiz}
                    >
                        Start Quiz
                    </button>
                </div>
            ) : (
                <div>
                    <h1 className="text-3xl font-bold mb-4 text-center">Logic Gate Quiz</h1>
                    <p className="text-xl text-center mb-4">Score: {score}</p>
                    <div className="flex justify-center items-center mb-8">
                        <div className="inputs text-center mr-8">
                            <h3 className="text-2xl mb-4">Inputs</h3>
                            <div className="flex justify-center space-x-4">
                                <div className="bg-gray-200 p-4 rounded-lg text-2xl font-mono w-16">{inputs[0]}</div>
                                <div className="bg-gray-200 p-4 rounded-lg text-2xl font-mono w-16">{inputs[1]}</div>
                            </div>
                        </div>
                        <div className="desired-output text-center ml-8">
                            <h3 className="text-2xl mb-4">Desired Output</h3>
                            <div className="bg-gray-200 p-4 rounded-lg text-2xl font-mono w-16">{desiredOutput}</div>
                        </div>
                    </div>
                    <div className="gate-selection text-center mb-8">
                        <h3 className="text-2xl mb-4">Select the Correct Gate</h3>
                        <div className="flex justify-center flex-wrap gap-4">
                            {gates.map((gate, index) => (
                                <button
                                    key={index}
                                    className={`bg-white shadow-lg rounded-lg p-4 cursor-pointer transition-transform transform hover:scale-105 ${selectedGate === gate ? 'ring-2 ring-blue-500' : ''}`}
                                    onClick={() => handleGateSelection(gate)}
                                >
                                    {gate.label}
                                </button>
                            ))}
                        </div>
                    </div>
                    {feedback && (
                        <div className={`text-center text-xl ${feedback.includes('Correct') ? 'text-green-600' : 'text-red-600'}`}>
                            {feedback}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default LogicGateQuiz;