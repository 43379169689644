import React, { useState, useEffect } from 'react';

function generateRandomBinary() {
    const randomNumber = Math.floor(Math.random() * 65); // Random number between 0 and 64
    return randomNumber.toString(2).padStart(6, '0'); // Convert to 6-bit binary
}

function BinaryCodeBreaker() {
    const [binaryCode, setBinaryCode] = useState(generateRandomBinary());
    const [userInput, setUserInput] = useState('');
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(60);
    const [gameStarted, setGameStarted] = useState(false);
    const [showInstructions, setShowInstructions] = useState(true);

    useEffect(() => {
        let timer;
        if (gameStarted) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [gameStarted]);

    useEffect(() => {
        if (timeLeft <= 0) {
            alert('Time is up! Your final score is ' + score);
            resetGame();
        }
    }, [timeLeft, score]);

    useEffect(() => {
        const binaryNumber = parseInt(binaryCode, 2);
        if (userInput === binaryNumber.toString()) {
            setScore((prevScore) => prevScore + 10);
            setBinaryCode(generateRandomBinary());
            setUserInput('');
        }
    }, [userInput, binaryCode]);

    const startGame = () => {
        setGameStarted(true);
        setShowInstructions(false);
    };

    const resetGame = () => {
        setScore(0);
        setTimeLeft(60);
        setBinaryCode(generateRandomBinary());
        setGameStarted(false);
        setShowInstructions(true);
    };

    return (
        <div className="container mx-auto p-4">
            {showInstructions ? (
                <div className="flex flex-col justify-center items-center min-h-screen">
                    <h1 className="text-3xl font-bold mb-4">Binary CodeBreaker</h1>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">What is Binary Code?</h2>
                        <p className="mb-2">Binary code is a system of representing text or computer processor instructions using two digits: 0 and 1. It's the most basic form of data in computing and digital communications.</p>
                        <p className="mb-2">In this game, you'll see 6-bit binary numbers. Each binary digit (bit) represents a power of 2:</p>
                        <p className="font-mono text-center mb-2">32 - 16 - 8 - 4 - 2 - 1</p>
                        <p className="mb-2">For example, the binary number 101010 represents:</p>
                        <p className="font-mono text-center mb-2">1*32 + 0*16 + 1*8 + 0*4 + 1*2 + 0*1 = 42</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">How to Play</h2>
                        <ol className="list-decimal list-inside">
                            <li>You'll see a 6-digit binary number.</li>
                            <li>Convert this binary number to decimal (base-10).</li>
                            <li>Enter your answer in the input field.</li>
                            <li>You have 60 seconds to solve as many codes as possible.</li>
                            <li>Each correct answer gives you 10 points.</li>
                        </ol>
                    </div>
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={startGame}
                    >
                        Start Game
                    </button>
                </div>
            ) : (
                <div>
                    <h1 className="text-3xl font-bold mb-4 text-center">Binary CodeBreaker</h1>
                    <div className="text-center mb-8">
                        <h2 className="text-xl">You are a spy. Decode the binary codes before the bomb explodes!</h2>
                        <h2 className="text-2xl font-bold">Time Left: {timeLeft}s</h2>
                        <h2 className="text-2xl font-bold">Score: {score}</h2>
                    </div>
                    <div className="flex justify-center items-center mb-8">
                        <div className="text-center mr-4">
                            <h3 className="text-2xl mb-4">Binary Code</h3>
                            <div className="bg-gray-200 p-4 rounded-lg text-2xl font-mono">{binaryCode}</div>
                        </div>
                        <div className="text-center">
                            <h3 className="text-2xl mb-4">Decimal</h3>
                            <input
                                type="number"
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                className="w-full px-4 py-2 border rounded-md text-2xl text-center"
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BinaryCodeBreaker;