import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import awsconfig from './aws-exports';
import reportWebVitals from "./reportWebVitals";
import Amplify, { Auth } from 'aws-amplify';
import MusicTest2 from "./pages/MusicTest2";
import LanguageApp from "./pages/LanguageApp";
import TimelineGame from "./pages/AppleHistoryTimeline";
import LogicGateGame from "./pages/AppleLogicGate";
import BinaryCodeBreaker from "./pages/AppleBinaryCode";
import IconicVoicesGame from "./pages/AppleIcons";
import TuringTest from "./pages/AppleTuringTest";
import PunchCardSimulator from "./pages/PunchCardSimulator";
import EasyComputerBug from "./pages/AppleDebugger";
import EncryptionPuzzle from "./pages/AppleEnigma";
import DroppedPunchCards from "./pages/AppleDroppedPunchCards";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="sheet-music-app" element={<MusicTest2 />} />
                    <Route path="language-app" element={<LanguageApp />} />
                    <Route path="apple-timeline" element={<TimelineGame />} />
                    <Route path="logic-gate" element={<LogicGateGame />} />
                    <Route path="binary-code" element={<BinaryCodeBreaker />} />
                    <Route path="punch-card" element={<PunchCardSimulator />} />
                    <Route path="dropped-punch-card" element={<DroppedPunchCards />} />
                    <Route path="debugger" element={<EasyComputerBug />} />
                    <Route path="enigma" element={<EncryptionPuzzle />} />
                    <Route path="apple-icons" element={<IconicVoicesGame />} />
                    <Route path="turing-test" element={<TuringTest />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
