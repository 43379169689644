import React, { useState, useEffect } from 'react';

const quotes = [
    { quote: "Stay hungry, stay foolish.", person: "Steve Jobs" },
    { quote: "The most powerful technology is technology that everyone can use.", person: "Tim Cook" },
    { quote: "Your time is limited, so don’t waste it living someone else’s life.", person: "Steve Jobs" },
    { quote: "We have always been shameless about stealing great ideas.", person: "Steve Jobs" },
    { quote: "It’s not about money. It’s about the people you have, how you’re led, and how much you get it.", person: "Steve Jobs" },
    { quote: "Never trust a computer you can't throw out a window.", person: "Steve Wozniak" },
    { quote: "Your most unhappy customers are your greatest source of learning.", person: "Bill Gates" },
    { quote: "I do not fear computers. I fear the lack of them.", person: "Isaac Asimov" },
    { quote: "I think it's fair to say that personal computers have become the most empowering tool we've ever created.", person: "Bill Gates" },
    { quote: "Sometimes it is the people no one can imagine anything of who do the things no one can imagine.", person: "Alan Turing" },
    { quote: "Those who can imagine anything, can create the impossible.", person: "Alan Turing" },
    { quote: "A computer would deserve to be called intelligent if it could deceive a human into believing that it was human.", person: "Alan Turing" },
    { quote: "Machines take me by surprise with great frequency.", person: "Alan Turing" },
    { quote: "We can only see a short distance ahead, but we can see plenty there that needs to be done.", person: "Alan Turing" },
    { quote: "Sometimes when you innovate, you make mistakes. It is best to admit them quickly, and get on with improving your other innovations.", person: "Steve Jobs" },
    { quote: "The Internet is becoming the town square for the global village of tomorrow.", person: "Bill Gates" },
    { quote: "Computing is not about computers anymore. It is about living.", person: "Nicholas Negroponte" },
    { quote: "The number one benefit of information technology is that it empowers people to do what they want to do.", person: "Steve Ballmer" },
    { quote: "I was lucky enough to be in the right place at the right time.", person: "Steve Wozniak" },
    { quote: "To create a new standard, it takes something that's not just a little bit different. It takes something that's really new and really captures people's imagination.", person: "Bill Gates" },
    { quote: "The only way to do great work is to love what you do.", person: "Steve Jobs" },
    { quote: "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.", person: "Steve Jobs" },
    { quote: "A lot of people in our industry haven't had very diverse experiences. So they don't have enough dots to connect, and they end up with very linear solutions without a broad perspective on the problem.", person: "Steve Jobs" },
    { quote: "Don't compare yourself with anyone in this world. If you do so, you are insulting yourself.", person: "Bill Gates" },
    { quote: "We’re just enthusiastic about what we do.", person: "Steve Jobs" },
    { quote: "It’s not a faith in technology. It’s faith in people.", person: "Steve Jobs" },
];

const people = ["Steve Jobs", "Steve Wozniak", "Bill Gates", "Isaac Asimov", "Alan Turing", "Steve Ballmer"];

function getRandomPeople(correctPerson) {
    const shuffledPeople = people.filter(person => person !== correctPerson).sort(() => 0.5 - Math.random());
    const selectedPeople = shuffledPeople.slice(0, 2);
    selectedPeople.push(correctPerson);
    return selectedPeople.sort(() => 0.5 - Math.random());
}

function IconicVoicesGame() {
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [message, setMessage] = useState('');
    const [randomPeople, setRandomPeople] = useState(getRandomPeople(quotes[0].person));

    useEffect(() => {
        setRandomPeople(getRandomPeople(quotes[currentQuoteIndex].person));
    }, [currentQuoteIndex]);

    const handlePersonClick = (person) => {
        const correctPerson = quotes[currentQuoteIndex].person;
        if (person === correctPerson) {
            setScore(score + 1);
            setMessage('Correct!');
        } else {
            setMessage(`Incorrect. The correct answer was ${correctPerson}.`);
        }
        setSelectedPerson(person);
        setTimeout(() => {
            setCurrentQuoteIndex((currentQuoteIndex + 1) % quotes.length);
            setSelectedPerson(null);
            setMessage('');
        }, 2000);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Iconic Voices of Apple</h1>
            <div className="text-center mb-8">
                <h2 className="text-xl">Match the quote to the correct Apple executive or famous figure.</h2>
                <h2 className="text-2xl font-bold">Score: {score}</h2>
            </div>
            <div className="flex justify-center items-center mb-8">
                <div className="text-center mr-4">
                    <h3 className="text-2xl mb-4">Quote</h3>
                    <div className="bg-gray-200 p-4 rounded-lg text-2xl">{quotes[currentQuoteIndex].quote}</div>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mb-8">
                {randomPeople.map((person) => (
                    <button
                        key={person}
                        onClick={() => handlePersonClick(person)}
                        className={`p-4 bg-white shadow-lg rounded-lg text-xl font-semibold transition duration-300 ease-in-out transform hover:scale-105 ${
                            selectedPerson === person ? (person === quotes[currentQuoteIndex].person ? 'bg-green-200' : 'bg-red-200') : ''
                        }`}
                    >
                        {person}
                    </button>
                ))}
            </div>
            <div className="text-center">
                {message && <p className="text-lg text-gray-700 mt-2">{message}</p>}
            </div>
        </div>
    );
}

export default IconicVoicesGame;
