import React, { useState, useEffect } from 'react';


const sampleMessages = [
    {
        message: "HELLO WORLD",
        encrypted: "IFMMP XPSME",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "ENIGMA MACHINE",
        encrypted: "FOJHNB NBDIJOF",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "ALAN TURING",
        encrypted: "BMBO UVSJOH",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "SECRET CODE",
        encrypted: "TFDSFU DPEF",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "ENCRYPT MESSAGE",
        encrypted: "FODSZQU NFTTBHF",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "DECODE PHRASE",
        encrypted: "EDFPEF QISBTF",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "HIDDEN TEXT",
        encrypted: "IJEEFO UFYU",
        cipher: "Caesar Cipher",
        shift: 1,
        description: "A Caesar Cipher shifts each letter of the message by a fixed number of positions in the alphabet."
    },
    {
        message: "HELLO WORLD",
        encrypted: "SVOOL DLIOW",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    },
    {
        message: "ENIGMA MACHINE",
        encrypted: "VMRMTZ NZXSRMV",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    },
    {
        message: "ALAN TURING",
        encrypted: "ZOLM GFIRMT",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    },
    {
        message: "SECRET CODE",
        encrypted: "HVXVIG XLWV",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    },
    {
        message: "ENCRYPT MESSAGE",
        encrypted: "VMXIBKGV NVHHZTGV",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    },
    {
        message: "DECODE PHRASE",
        encrypted: "WVXLWV KIRZHV",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    },
    {
        message: "HIDDEN TEXT",
        encrypted: "SRWWVM GVHG",
        cipher: "Atbash Cipher",
        description: "An Atbash Cipher maps each letter of the alphabet to its reverse (e.g., A ↔ Z, B ↔ Y, etc.)."
    }
];



function getRandomMessage(messages) {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
}

function decryptMessage(encrypted, shift) {
    return encrypted
        .split('')
        .map(char => {
            const charCode = char.charCodeAt(0);
            if (charCode >= 65 && charCode <= 90) {
                return String.fromCharCode(((charCode - 65 - shift + 26) % 26) + 65);
            } else {
                return char;
            }
        })
        .join('');
}

function EncryptionPuzzle() {
    const [showInstructions, setShowInstructions] = useState(true);
    const [gameStarted, setGameStarted] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [decryptedMessage, setDecryptedMessage] = useState([]);
    const [message, setMessage] = useState('');
    const [score, setScore] = useState(0);

    useEffect(() => {
        if (gameStarted) {
            loadRandomMessage();
        }
    }, [gameStarted]);

    const loadRandomMessage = () => {
        const randomMessage = getRandomMessage(sampleMessages);
        setCurrentMessage(randomMessage);
        setDecryptedMessage([]);
        setMessage('');
    };

    const handleLetterClick = (letter) => {
        setDecryptedMessage([...decryptedMessage, letter]);
    };

    const handleSubmission = () => {
        const userMessage = decryptedMessage.join('');
        if (userMessage === currentMessage.message) {
            setScore(score + 1);
            setMessage('Correct! You decrypted the message.');
        } else {
            setMessage('Incorrect. Try again.');
        }
        setTimeout(() => {
            setMessage('');
            loadRandomMessage();
        }, 2000);
    };

    const startGame = () => {
        setGameStarted(true);
    };

    return (
        <div className="container mx-auto p-4">
            {showInstructions ? (
                <div className="flex flex-col justify-center items-center min-h-screen">
                    <h1 className="text-3xl font-bold mb-4">Encryption Puzzle Game</h1>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">History of Enigma and Alan Turing</h2>
                        <p className="mb-2">The Enigma machine was a cipher device used by the German military command to encode strategic messages before and during World War II. Alan Turing, a pioneering computer scientist, played a crucial role in decrypting the Enigma code, significantly contributing to the Allied victory.</p>
                        <p className="mb-2">In this exhibit, you will learn about various historical encryption methods and try your hand at decrypting messages.</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">How to Play</h2>
                        <ol className="list-decimal list-inside">
                            <li>You will be given an encrypted message using a historical cipher.</li>
                            <li>Your task is to decrypt the message by selecting the correct letters in order.</li>
                            <li>If you are correct, you earn a point.</li>
                            <li>The game will then provide a new encrypted message for you to solve.</li>
                        </ol>
                    </div>
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={() => { setShowInstructions(false); startGame(); }}
                    >
                        Start Game
                    </button>
                </div>
            ) : (
                <div>
                    <h1 className="text-3xl font-bold mb-4 text-center">Encryption Puzzle Game</h1>
                    <div className="text-center mb-8">
                        <h2 className="text-xl">Decrypt the Message</h2>
                        <h2 className="text-2xl font-bold">Score: {score}</h2>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl mx-auto">
                        <h3 className="text-2xl mb-4">Encrypted Message</h3>
                        <div className="bg-gray-200 p-4 rounded-lg text-2xl font-mono mb-4">
                            {currentMessage && currentMessage.encrypted}
                        </div>
                        <h3 className="text-xl mb-2">Cipher: {currentMessage && currentMessage.cipher}</h3>
                        <p className="mb-4">{currentMessage && currentMessage.description}</p>
                        <div className="flex justify-center mb-4">
                            {currentMessage && [...new Set(currentMessage.message.split(''))].sort().map((char, index) => (
                                <button
                                    key={index}
                                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded m-1"
                                    onClick={() => handleLetterClick(char)}
                                >
                                    {char}
                                </button>
                            ))}
                        </div>
                        <div className="bg-gray-200 p-4 rounded-lg text-2xl font-mono mb-4">
                            {decryptedMessage.join('')}
                        </div>
                        <button
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded"
                            onClick={handleSubmission}
                        >
                            Submit
                        </button>
                        {message && <p className="text-lg text-gray-700 mt-2 text-center">{message}</p>}
                    </div>
                </div>
            )}
        </div>
    );
}

export default EncryptionPuzzle;
