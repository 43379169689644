import React from 'react';

function Home() {
    return (
        <><h1>this is the homepage</h1>
        <p>This is the first section</p>
        <h2>Sub-header 1</h2>
        <p>Some stuff here</p>
        <h2>Sub-header 2</h2>
        <p>More stuff here</p>
            </>
    );
}

export default Home;