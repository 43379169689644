import React, { useState, useEffect } from 'react';

function getRandomFile(files) {
    const randomIndex = Math.floor(Math.random() * files.length);
    return files[randomIndex];
}

function TuringTest() {
    const [realFile, setRealFile] = useState(null);
    const [aiFile, setAiFile] = useState(null);
    const [leftIsAi, setLeftIsAi] = useState(false);
    const [score, setScore] = useState(0);
    const [message, setMessage] = useState('');
    const [gameStarted, setGameStarted] = useState(false);
    const [realText, setRealText] = useState('');
    const [aiText, setAiText] = useState('');

    useEffect(() => {
        if (gameStarted) {
            loadRandomFiles();
        }
    }, [gameStarted]);

    const loadRandomFiles = async () => {
        const response = await fetch('/fileList.json');
        const fileList = await response.json();
        const randomFile = getRandomFile(fileList.real); // Pick a random file name from real folder
        setRealFile(randomFile);
        setAiFile(randomFile); // Same file name in ai folder for comparison
        setLeftIsAi(Math.random() < 0.5); // Randomize AI file placement

        if (randomFile.endsWith('.txt')) {
            const realTextContent = await fetch(`/real/${randomFile}`).then(response => response.text());
            const aiTextContent = await fetch(`/ai/${randomFile}`).then(response => response.text());
            setRealText(realTextContent);
            setAiText(aiTextContent);
        }
    };

    const handleSelection = (choice) => {
        if ((choice === 'left' && leftIsAi) || (choice === 'right' && !leftIsAi)) {
            setScore(score + 1);
            setMessage('Correct! The AI-generated file was selected.');
        } else {
            setMessage('Incorrect. The AI-generated file was not selected.');
        }
        setTimeout(() => {
            setMessage('');
            loadRandomFiles();
        }, 2000);
    };

    const startGame = () => {
        setGameStarted(true);
    };

    return (
        <div className="container mx-auto p-4">
            {!gameStarted ? (
                <div className="flex flex-col justify-center items-center min-h-screen">
                    <h1 className="text-3xl font-bold mb-4">Turing Test: Identify the Replicant!</h1>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">What is a Turing Test?</h2>
                        <p className="mb-2">The Turing Test is a method of inquiry in artificial intelligence for determining whether or not a computer is capable of thinking like a human being.</p>
                        <p className="mb-2">In this game, you will be presented with two files. One is written by a human, and the other by an AI. Your task is to identify the AI-generated file.</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">How to Play</h2>
                        <ol className="list-decimal list-inside">
                            <li>You will see two files: Option 1 and Option 2.</li>
                            <li>Read both files carefully.</li>
                            <li>Decide which file you think is AI-generated.</li>
                            <li>Select the option you believe is AI-generated.</li>
                            <li>If you are correct, you earn a point.</li>
                        </ol>
                    </div>
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={startGame}
                    >
                        Start
                    </button>
                </div>
            ) : (
                <div>
                    <h1 className="text-3xl font-bold mb-4 text-center">Turing Test: Identify the Replicant!</h1>
                    <div className="text-center mb-8">
                        <h2 className="text-xl">Can you identify which one is AI-generated?</h2>
                        <h2 className="text-2xl font-bold">Score: {score}</h2>
                    </div>
                    <div className="flex justify-center items-center mb-8">
                        <div className="text-center mr-4">
                            <h3 className="text-2xl mb-4">Option 1</h3>
                            {leftIsAi ? (
                                <>
                                    {aiFile && aiFile.endsWith('.png') && (
                                        <img src={`/ai/${aiFile}`} alt="AI Option" className="mx-auto mb-2 w-64 h-64 object-contain" />
                                    )}
                                    {aiFile && aiFile.endsWith('.txt') && (
                                        <pre className="bg-gray-200 p-4 rounded-lg">
                                            {aiText}
                                        </pre>
                                    )}
                                </>
                            ) : (
                                <>
                                    {realFile && realFile.endsWith('.png') && (
                                        <img src={`/real/${realFile}`} alt="Real Option" className="mx-auto mb-2 w-64 h-64 object-contain" />
                                    )}
                                    {realFile && realFile.endsWith('.txt') && (
                                        <pre className="bg-gray-200 p-4 rounded-lg">
                                            {realText}
                                        </pre>
                                    )}
                                </>
                            )}
                            <button
                                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mt-4"
                                onClick={() => handleSelection('left')}
                            >
                                Select Option 1
                            </button>
                        </div>
                        <div className="text-center ml-4">
                            <h3 className="text-2xl mb-4">Option 2</h3>
                            {leftIsAi ? (
                                <>
                                    {realFile && realFile.endsWith('.png') && (
                                        <img src={`/real/${realFile}`} alt="Real Option" className="mx-auto mb-2 w-64 h-64 object-contain" />
                                    )}
                                    {realFile && realFile.endsWith('.txt') && (
                                        <pre className="bg-gray-200 p-4 rounded-lg">
                                            {realText}
                                        </pre>
                                    )}
                                </>
                            ) : (
                                <>
                                    {aiFile && aiFile.endsWith('.png') && (
                                        <img src={`/ai/${aiFile}`} alt="AI Option" className="mx-auto mb-2 w-64 h-64 object-contain" />
                                    )}
                                    {aiFile && aiFile.endsWith('.txt') && (
                                        <pre className="bg-gray-200 p-4 rounded-lg">
                                            {aiText}
                                        </pre>
                                    )}
                                </>
                            )}
                            <button
                                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mt-4"
                                onClick={() => handleSelection('right')}
                            >
                                Select Option 2
                            </button>
                        </div>
                    </div>
                    <div className="text-center">
                        {message && <p className="text-lg text-gray-700 mt-2">{message}</p>}
                    </div>
                </div>
            )}
        </div>
    );
}

export default TuringTest;
