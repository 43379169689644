import React, { useState, useEffect } from 'react';
const samplePrograms = [
    {
        code: [
            "def add(a, b):",
            "    return a - b",  // Bug is here
            "",
            "result = add(2, 3)",
            "print(result)"
        ],
        bugLine: 2
    },
    {
        code: [
            "def find_max(numbers):",
            "    max_num = numbers[0]",
            "    for num in numbers:",
            "        if num < max_num:",  // Bug is here
            "            max_num = num",
            "    return max_num",
            "",
            "print(find_max([1, 2, 3, 4]))"
        ],
        bugLine: 4
    },
    {
        code: [
            "def sum_list(lst):",
            "    total = 0",
            "    for num in lst:",
            "        total = total + lst",  // Bug is here
            "    return total",
            "",
            "print(sum_list([1, 2, 3, 4]))"
        ],
        bugLine: 4
    },
    {
        code: [
            "def reverse_string(s):",
            "    return s[::-1]",
            "",
            "result = reverse_string(12345)",  // Bug is here
            "print(result)"
        ],
        bugLine: 3
    },
    {
        code: [
            "def is_even(n):",
            "    if n % 2 = 0:",  // Bug is here
            "        return True",
            "    else:",
            "        return False",
            "",
            "print(is_even(4))"
        ],
        bugLine: 2
    },
    {
        code: [
            "def greet(name):",
            "    greeting = 'Hello ' + name",
            "    return greeting",
            "",
            "print(greet())",  // Bug is here
        ],
        bugLine: 4
    },
    {
        code: [
            "def multiply(a, b):",
            "    return a * b",
            "",
            "result = multiply(3)",  // Bug is here
            "print(result)"
        ],
        bugLine: 3
    },
    {
        code: [
            "def divide(a, b):",
            "    return a / b",
            "",
            "result = divide(4, 0)",  // Bug is here
            "print(result)"
        ],
        bugLine: 3
    },
    {
        code: [
            "def concatenate(a, b):",
            "    return a + b",
            "",
            "result = concatenate('Hello', 123)",  // Bug is here
            "print(result)"
        ],
        bugLine: 3
    }
];


function getRandomProgram(programs) {
    const randomIndex = Math.floor(Math.random() * programs.length);
    return programs[randomIndex];
}

function EasyComputerBug() {
    const [showInstructions, setShowInstructions] = useState(true);
    const [gameStarted, setGameStarted] = useState(false);
    const [currentProgram, setCurrentProgram] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [message, setMessage] = useState('');
    const [score, setScore] = useState(0);

    useEffect(() => {
        if (gameStarted) {
            loadRandomProgram();
        }
    }, [gameStarted]);

    const loadRandomProgram = () => {
        const randomProgram = getRandomProgram(samplePrograms);
        setCurrentProgram(randomProgram);
        setUserInput('');
        setMessage('');
    };

    const handleSubmission = () => {
        if (parseInt(userInput) === currentProgram.bugLine) {
            setScore(score + 1);
            setMessage('Correct! You found the bug.');
        } else {
            setMessage('Incorrect. Try again.');
        }
        setTimeout(() => {
            setMessage('');
            loadRandomProgram();
        }, 2000);
    };

    const startGame = () => {
        setGameStarted(true);
    };

    return (
        <div className="container mx-auto p-4">
            {showInstructions ? (
                <div className="flex flex-col justify-center items-center min-h-screen">
                    <h1 className="text-3xl font-bold mb-4">Find the Bug!</h1>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">What is Debugging?</h2>
                        <p className="mb-2">Debugging is the process of identifying and removing errors or bugs from a computer program. It is a crucial part of software development and ensures that the program runs correctly and efficiently.</p>
                        <p className="mb-2">The term "debugging" was popularized by Grace Hopper, a pioneering computer scientist, when she removed a moth from a computer and referred to it as "debugging".</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">How to Play</h2>
                        <ol className="list-decimal list-inside">
                            <li>You will be presented with a simple Python program.</li>
                            <li>Identify the line number where you think the bug is.</li>
                            <li>Enter the line number and submit your answer.</li>
                            <li>If you are correct, you earn a point.</li>
                            <li>The game will load a new program for you to debug.</li>
                        </ol>
                    </div>
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={() => { setShowInstructions(false); startGame(); }}
                    >
                        Start Game
                    </button>
                </div>
            ) : (
                <div>
                    <h1 className="text-3xl font-bold mb-4 text-center">Find the Bug!</h1>
                    <div className="text-center mb-8">
                        <h2 className="text-xl">Find the Bug in the Program</h2>
                        <h2 className="text-2xl font-bold">Score: {score}</h2>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl mx-auto">
                        <pre className="bg-gray-200 p-4 rounded-lg">
                            {currentProgram && currentProgram.code.map((line, index) => (
                                <div key={index}>{index + 1}: {line}</div>
                            ))}
                        </pre>
                        <div className="flex justify-center mt-4">
                            <input
                                type="number"
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                className="w-20 px-4 py-2 border rounded-md text-2xl text-center"
                                placeholder="Line #"
                            />
                            <button
                                className="bg-blue-500 text-white font-bold py-2 px-4 rounded ml-4"
                                onClick={handleSubmission}
                            >
                                Submit
                            </button>
                        </div>
                        {message && <p className="text-lg text-gray-700 mt-2 text-center">{message}</p>}
                    </div>
                </div>
            )}
        </div>
    );
}

export default EasyComputerBug;
