import React, { useEffect, useState, useRef } from "react";
import abcjs from "abcjs";

export default function Abc({ abc }) {
  const [abcTune, setAbcTune] = useState(abc);
  const inputEl = useRef(null);
  const [visualObj, setVisualObj] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const synthRef = useRef(null);

  useEffect(() => {
    if (inputEl.current && abcTune) {
      const visualObjs = abcjs.renderAbc(inputEl.current, abcTune, {
        add_classes: true,
        staffwidth: 740,
        wrap: { preferredMeasuresPerLine: 6 },
        print: false,
      });
      setVisualObj(visualObjs[0]);
    }
  }, [abcTune]);

  useEffect(() => {
    setAbcTune(abc);
  }, [abc]);

  const handlePlayPause = async () => {
    if (!visualObj) return;

    if (!synthRef.current) {
      const synth = new abcjs.synth.CreateSynth();
      await synth.init({ visualObj: visualObj });
      await synth.prime();
      synthRef.current = synth;
    }

    if (isPlaying) {
      synthRef.current.stop();
      setIsPlaying(false);
    } else {
      synthRef.current.start();
      setIsPlaying(true);
    }
  };


  return (
      <div>
        <div ref={inputEl}></div>
        <div className="mt-4 space-x-3 flex justify-center">
          <button onClick={handlePlayPause} className="p-3 px-6 text-white rounded-full bg-darkishBlue">
            {isPlaying ? "Pause" : "Play"}
          </button>
        </div>
        {audioUrl && (
            <div className="mt-4">
              <audio controls src={audioUrl} className="w-full mt-2"></audio>
            </div>
        )}
      </div>
  );
}
