import React, { useState, useEffect } from 'react';
import '../App.css';
import { API, Auth } from 'aws-amplify';
import AbcComponent from "../AbcComponent";
const { Configuration, OpenAIApi } = require("openai");

async function getUserId() {
  const auth_promise = await Auth.currentSession().then(res => {
    let accessToken = res.getAccessToken();
    return accessToken.payload;
  });

  return auth_promise;
}

function MusicTest2() {
  const initialFormState = { title: 'Two of Us', ai_author: 'The Beatles', description: '', score: 0, username: '', is_public: false };
  const [Songs, setSongs] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  let user_name = 'root-temp';

  useEffect(() => {
    getUserId().then(value => {
      setFormData({ ...formData, username: value.username });
      user_name = value.username;
      fetchSongs(value.username);
    }).catch(err => {
      console.log('ERROR ', err);
    });
  }, []);

  const [state, setState] = useState({ key: 'C', temperature: 1.0, frequency_penalty: 0.15, max_tokens: 460, top_p: 0.9 });

  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  async function fetchSongs(username) {
    console.log("fetching data with user", username);
    try {
      const response = await API.get('yourApiName', `/songs?username=${username}`);
      setSongs(response);
    } catch (error) {
      console.log('Error fetching songs:', error);
    }
  }

  async function createSong() {
    const header_string = "You are SongBot, who creates NEW songs on request. You ONLY " +
        "respond with valid \".abc\" file text. Your job is to write a new song (T) called " + formData.title +
        " in the style of " + formData.ai_author +
        " (C) and in the key (K) of " + state.key + ", and provide it in a VALID \".abc\" file format below. The song must also have lyrics (w) that are properly " +
        "included with the 'w:' tag interspersed every other line with the music notes. There should be enough notes for the words included in the next line. I give a simple example of the format here:\n" +
        "X:1\n" +
        "T:Example Title\n" +
        "C:(Composer who inspired composition)\n" +
        "M:4/4\n" +
        "L:1/8\n" +
        "K:C\n" +
        "\"A\"A2 AB AG E2 | \"D\"DEGA D3 E |\n" +
        "w:words ev-ery o- ther line\n" +
        "\"G\"GADE B2 AB | \"A\"A2 A2 GA A |\n" +
        "w:al- ter- nat- ing, with the mu- sic";

    const full_prompt = additionalInstructions ? `${header_string}\n${additionalInstructions}` : header_string;

    const chatCompletionRequest = {
      'model': "gpt-4o",
      'temperature': state.temperature,
      'messages': [{ "role": "user", "content": full_prompt }]
    };

    try {
      const completion = await openai.createChatCompletion(chatCompletionRequest);
      let completion_text = completion.data.choices[0].message.content;
      const newSong = { ...formData, description: completion_text.replace(/^\s*\n/gm, '') };
      console.log("Completion is :\n" + newSong.description);
      setSongs([ ...Songs, newSong ]);
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.data);
      } else {
        console.log(error.message);
      }
    }
  }

  return (
      <div className="App space-y-7">
        <h1 className="text-2xl">Sheet Music Idea Generator</h1>
        <input
            onChange={e => setFormData({...formData, 'title': e.target.value})}
            placeholder={'Song title'}
            value={formData.title}
        />
        <input
            onChange={e => setFormData({...formData, 'ai_author': e.target.value})}
            placeholder={'In the style of...'}
            value={formData.ai_author}
        />
        <input
            onChange={e => setState({...state, 'key': e.target.value})}
            placeholder={'C'}
            value={state.key}
        />
        <button className="p3 px-6 pt-2 text-white rounded-full bg-darkishBlue" onClick={createSong}>Generate Song
        </button>
        <div className="mt-4 flex justify-center">
          <input
              className="w-full max-w-2xl"
              onChange={e => setAdditionalInstructions(e.target.value)}
              placeholder={'Additional instructions (optional)'}
              value={additionalInstructions}
          />
        </div>
        <div className="App space-y-4 flex justify-center">
          {
            Songs.map((song, index) => (
                <div key={index}>
                  <AbcComponent abc={song.description}/>
                </div>
            ))
          }
        </div>
      </div>

  );
}

export default MusicTest2;
