import React from "react";
import { Link } from "react-router-dom";
import feather from './feather.svg';
import TuringTest from "./pages/AppleTuringTest";

function Navbar() {
  return (
    <nav className="relative container mx-auto p-6">
      <div className="flex items-center justify-between">
        <div className="flex space-x-10">
          <img src={feather} width="30" height="30" />
          <Link to="/" className="hover:text-burnOrange">Home</Link>
          {/*<Link to="/dashboard" className="hover:text-burnOrange">Dashboard</Link>*/}
          <Link to="/sheet-music-app" className="hover:text-burnOrange">Sheet Music Generator</Link>
          <Link to="/language-app" className="hover:text-burnOrange">Learn Languages</Link>
          <Link to="/apple-timeline" className="hover:text-burnOrange">Apple Timeline</Link>
          <Link to="/logic-gate" className="hover:text-burnOrange">Learn Logic Gates</Link>
          <Link to="/binary-code" className="hover:text-burnOrange">Learn Binary</Link>
          <Link to="/punch-card" className="hover:text-burnOrange">Punch Cards</Link>
          <Link to="/dropped-punch-card" className="hover:text-burnOrange">Dropped Punch Cards!</Link>
          <Link to="/debugger" className="hover:text-burnOrange">Debug Game</Link>
          <Link to="/enigma" className="hover:text-burnOrange">Decryption Game</Link>
          <Link to="/apple-icons" className="hover:text-burnOrange">Quote Matching</Link>
          <Link to="/turing-test" className="hover:text-burnOrange">Turing Test</Link>
          {/*<Link to="/write-poems" className="hover:text-burnOrange">Write Poems</Link>*/}
          {/*<Link to="/public-poems" className="hover:text-burnOrange">Read Poems</Link>*/}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;