import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemTypes = {
    CARD: 'card'
};

const Card = ({ id, text, index, moveCard }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const ref = React.useRef(null);
    const opacity = isDragging ? 0.5 : 1;
    drag(drop(ref));

    return (
        <div ref={ref} className="bg-yellow-100 border-2 border-yellow-300 p-2 mb-2 cursor-move" style={{ opacity }}>
            {text}
        </div>
    );
};

const DroppedPunchCards = () => {
    const [showInstructions, setShowInstructions] = useState(true);
    const [cards, setCards] = useState([]);
    const [goal, setGoal] = useState('');
    const [output, setOutput] = useState('');
    const [score, setScore] = useState(0);

    const problems = [
        {
            goal: "Print 'HELLO WORLD'",
            cards: [
                "PRINT 'WORLD'",
                "PRINT 'HELLO'",
                "PRINT ' '",
            ],
            solution: ["PRINT 'HELLO'", "PRINT ' '", "PRINT 'WORLD'"],
        },
        {
            goal: "Calculate 5 + 3",
            cards: [
                "PRINT RESULT",
                "SET RESULT = 5",
                "ADD 3 TO RESULT",
            ],
            solution: ["SET RESULT = 5", "ADD 3 TO RESULT", "PRINT RESULT"],
        },
        {
            goal: "Print numbers from 1 to 3",
            cards: [
                "PRINT 2",
                "PRINT 3",
                "PRINT 1",
            ],
            solution: ["PRINT 1", "PRINT 2", "PRINT 3"],
        },
    ];

    const moveCard = (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(
            cards.map((card, idx, arr) => {
                if (idx === dragIndex) return arr[hoverIndex];
                if (idx === hoverIndex) return dragCard;
                return card;
            })
        );
    };

    const startGame = () => {
        setShowInstructions(false);
        loadNewProblem();
    };

    const loadNewProblem = () => {
        const problem = problems[Math.floor(Math.random() * problems.length)];
        setCards([...problem.cards].sort(() => Math.random() - 0.5));
        setGoal(problem.goal);
        setOutput('');
    };

    const runProgram = () => {
        let programOutput = '';
        cards.forEach(card => {
            if (card.startsWith('PRINT')) {
                programOutput += card.replace('PRINT', '').trim() + '\n';
            } else if (card.startsWith('SET')) {
                // Do nothing for now
            } else if (card.startsWith('ADD')) {
                // Do nothing for now
            }
        });
        setOutput(programOutput.trim());

        const currentProblem = problems.find(p => p.goal === goal);
        if (JSON.stringify(cards) === JSON.stringify(currentProblem.solution)) {
            setScore(score + 1);
            setTimeout(() => {
                alert('Correct! Moving to the next problem.');
                loadNewProblem();
            }, 500);
        } else {
            alert('Not quite right. Try again!');
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="container mx-auto p-4">
                {showInstructions ? (
                    <div className="flex flex-col justify-center items-center min-h-screen">
                        <h1 className="text-4xl font-bold mb-8 text-gray-800">Dropped Punch Cards</h1>
                        <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                            <h2 className="text-2xl font-semibold mb-4">How to Play</h2>
                            <ol className="list-decimal list-inside space-y-2">
                                <li>You'll be presented with a set of punch cards that have been dropped and mixed up.</li>
                                <li>Your goal is to reorder the cards to create a working program.</li>
                                <li>Drag and drop the cards to change their order.</li>
                                <li>When you think you have the correct order, click "Run Program".</li>
                                <li>If your program achieves the goal, you'll move on to the next problem.</li>
                                <li>Try to solve as many problems as you can!</li>
                            </ol>
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            onClick={startGame}
                        >
                            Start Game
                        </button>
                    </div>
                ) : (
                    <div>
                        <h1 className="text-3xl font-bold mb-4 text-center">Dropped Punch Cards</h1>
                        <p className="text-xl text-center mb-4">Score: {score}</p>
                        <div className="bg-gray-100 p-4 rounded-lg mb-4">
                            <h2 className="text-xl font-semibold mb-2">Goal: {goal}</h2>
                        </div>
                        <div className="mb-4">
                            {cards.map((card, i) => (
                                <Card key={i} id={i} text={card} index={i} moveCard={moveCard} />
                            ))}
                        </div>
                        <button
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-4"
                            onClick={runProgram}
                        >
                            Run Program
                        </button>
                        <div className="bg-black text-green-400 p-4 rounded font-mono">
                            <h3 className="text-xl font-bold mb-2">Output:</h3>
                            <pre className="whitespace-pre-wrap">{output}</pre>
                        </div>
                    </div>
                )}
            </div>
        </DndProvider>
    );
};

export default DroppedPunchCards;