import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const events = [
    { year: 1976, event: 'Apple was founded' },
    { year: 1977, event: 'Apple II was released' },
    { year: 1984, event: 'Macintosh was introduced' },
    { year: 1991, event: 'PowerBook was released' },
    { year: 1998, event: 'iMac G3 was released' },
    { year: 2001, event: 'iPod was launched' },
    { year: 2007, event: 'iPhone was introduced' },
    { year: 2010, event: 'iPad was released' },
    { year: 2015, event: 'Apple Watch was launched' },
    { year: 2020, event: 'M1 chip was introduced' },
];

const ItemType = {
    EVENT: 'event',
};

function EventCard({ event, year, placed }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemType.EVENT,
        item: { event, year },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: !placed,
    }));

    return (
        <div
            ref={drag}
            className={`bg-white shadow-lg rounded-lg p-4 cursor-pointer ${isDragging ? 'opacity-50' : ''} ${placed ? 'bg-green-200' : ''}`}
        >
            <p className="text-lg font-semibold text-gray-800">{event}</p>
        </div>
    );
}

function TimelineSlot({ year, onDrop, placedEvent }) {
    const [{ isOver }, drop] = useDrop({
        accept: ItemType.EVENT,
        drop: (item) => onDrop(item, year),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div
            ref={drop}
            className={`relative p-4 ${isOver ? 'bg-green-100' : ''}`}
        >
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-4 h-4 bg-gray-300 rounded-full border-4 border-white z-10"></div>
            <div className="ml-8">
                <p className="text-lg font-bold">{year}</p>
                {placedEvent && <div className="mt-2"><EventCard event={placedEvent.event} year={placedEvent.year} placed={true} /></div>}
            </div>
        </div>
    );
}

function TimelineGame() {
    const [showInstructions, setShowInstructions] = useState(true);
    const [gameStarted, setGameStarted] = useState(false);
    const [remainingEvents, setRemainingEvents] = useState([]);
    const [placedEvents, setPlacedEvents] = useState({});
    const [score, setScore] = useState(0);

    useEffect(() => {
        if (gameStarted) {
            setRemainingEvents(events);
        }
    }, [gameStarted]);

    const handleDrop = (item, year) => {
        if (item.year === year) {
            setPlacedEvents((prev) => ({ ...prev, [year]: item }));
            setRemainingEvents((prev) => prev.filter((event) => event.year !== item.year));
            setScore(score + 1);
        } else {
            alert('Incorrect! Try again.');
        }
    };

    const startGame = () => {
        setGameStarted(true);
        setShowInstructions(false);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="container mx-auto p-4">
                {showInstructions ? (
                    <div className="flex flex-col justify-center items-center min-h-screen">
                        <h1 className="text-4xl font-bold mb-8 text-gray-800">Apple History Timeline Game</h1>
                        <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                            <h2 className="text-2xl font-semibold mb-4">How to Play</h2>
                            <ol className="list-decimal list-inside space-y-2">
                                <li>Drag and drop the event cards to their correct years on the timeline.</li>
                                <li>If you place an event correctly, it will stay on the timeline.</li>
                                <li>If you make a mistake, you'll be alerted and can try again.</li>
                                <li>Your goal is to correctly place all events on the timeline.</li>
                            </ol>
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            onClick={startGame}
                        >
                            Start Game
                        </button>
                    </div>
                ) : (
                    <div>
                        <h1 className="text-3xl font-bold mb-4 text-center text-gray-800">Apple History Timeline Game</h1>
                        <p className="text-xl text-center mb-8">Score: {score} / {events.length}</p>
                        <div className="timeline-game mt-8">
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mb-8">
                                {remainingEvents.map((event) => (
                                    <EventCard key={event.year} event={event.event} year={event.year} placed={false} />
                                ))}
                            </div>
                            <div className="relative">
                                <div className="absolute left-8 top-0 bottom-0 w-1 bg-gray-300"></div>
                                {events.map((event) => (
                                    <TimelineSlot key={event.year} year={event.year} onDrop={handleDrop} placedEvent={placedEvents[event.year]} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DndProvider>
    );
}

export default TimelineGame;