import React, { useState } from 'react';

const PunchCardSimulator = () => {
    const [gameStarted, setGameStarted] = useState(false);
    const [cards, setCards] = useState(['']);
    const [output, setOutput] = useState('');
    const [memory, setMemory] = useState(new Array(100).fill(0));
    const [accumulator, setAccumulator] = useState(0);

    const exampleProgram = [
        "DATA 5",
        "DATA 3",
        "LOAD 0",
        "ADD 1",
        "STORE 2",
        "WRITE 2",
        "HALT"
    ];

    const operations = [
        { command: 'DATA', description: 'Stores a number in memory' },
        { command: 'LOAD', description: 'Loads a value from memory into the accumulator' },
        { command: 'STORE', description: 'Stores the accumulator value into memory' },
        { command: 'ADD', description: 'Adds a value from memory to the accumulator' },
        { command: 'SUB', description: 'Subtracts a value from memory from the accumulator' },
        { command: 'MULT', description: 'Multiplies the accumulator by a value from memory' },
        { command: 'DIV', description: 'Divides the accumulator by a value from memory' },
        { command: 'WRITE', description: 'Outputs a value from memory' },
        { command: 'HALT', description: 'Stops the program execution' }
    ];

    const addCard = () => {
        setCards([...cards, '']);
    };

    const updateCard = (index, value) => {
        const newCards = [...cards];
        newCards[index] = value.slice(0, 80).toUpperCase();
        setCards(newCards);
    };



    const runProgram = () => {
        let programOutput = '';
        let programCounter = 0;
        let programMemory = [...memory];
        let programAccumulator = accumulator;
        let isHalted = false;

        while (programCounter < cards.length && !isHalted) {
            const card = cards[programCounter].trim();
            const [command, arg] = card.split(/\s+/);

            switch (command) {
                case 'DATA':
                    programMemory[programCounter] = parseInt(arg) || 0;
                    break;
                case 'LOAD':
                    programAccumulator = programMemory[parseInt(arg)] || 0;
                    break;
                case 'STORE':
                    programMemory[parseInt(arg)] = programAccumulator;
                    break;
                case 'ADD':
                    programAccumulator += programMemory[parseInt(arg)] || 0;
                    break;
                case 'SUB':
                    programAccumulator -= programMemory[parseInt(arg)] || 0;
                    break;
                case 'MULT':
                    programAccumulator *= programMemory[parseInt(arg)] || 1;
                    break;
                case 'DIV':
                    if (programMemory[parseInt(arg)] !== 0) {
                        programAccumulator = Math.floor(programAccumulator / programMemory[parseInt(arg)]);
                    } else {
                        programOutput += "Error: Division by zero\n";
                    }
                    break;
                case 'WRITE':
                    programOutput += `Output: ${programMemory[parseInt(arg)]}\n`;
                    break;
                case 'HALT':
                    isHalted = true;
                    break;
                default:
                    programOutput += `Error: Unknown command "${command}" on card ${programCounter + 1}\n`;
            }

            programCounter++;
        }

        setOutput(programOutput || 'Program completed without output.');
        setMemory(programMemory);
        setAccumulator(programAccumulator);
    };

    const loadExampleProgram = () => {
        setCards(exampleProgram);
    };

    const startGame = () => {
        setGameStarted(true);
    };

    return (
        <div className="container mx-auto p-4">
            {!gameStarted ? (
                <div className="flex flex-col items-center justify-center min-h-screen">
                    <h1 className="text-3xl font-bold mb-4">Punch Card Simulator</h1>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">A Brief History of Punch Cards</h2>
                        <p className="mb-2">Punch cards were one of the earliest methods of input for computers, dating back to the 1800s. They were widely used in the early days of computing, from the 1960s through the 1980s.</p>
                        <p className="mb-2">Each card typically had 80 columns and 12 rows. Holes punched in specific positions represented data or program instructions.</p>
                        <p>Programmers would write their programs by punching holes in these cards, then feed them into a computer in a specific order to run their program.</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg mb-4 max-w-2xl">
                        <h2 className="text-xl font-semibold mb-2">How to Use the Simulator</h2>
                        <ol className="list-decimal list-inside">
                            <li>Add punch cards using the "Add Punch Card" button.</li>
                            <li>Type your program instructions on each card (max 80 characters per card).</li>
                            <li>Use the available operations listed on the main screen.</li>
                            <li>Click "Run Program" to execute your punch card program.</li>
                            <li>View the output in the console at the bottom.</li>
                        </ol>
                    </div>
                    <button
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={startGame}
                    >
                        Start Simulator
                    </button>
                </div>
            ) : (
                <div>
                    <h1 className="text-3xl font-bold mb-4 text-center">Punch Card Simulator</h1>
                    <div className="mb-4 flex justify-center space-x-2">
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            onClick={addCard}
                        >
                            Add Punch Card
                        </button>
                        <button
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={runProgram}
                        >
                            Run Program
                        </button>
                        <button
                            className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                            onClick={loadExampleProgram}
                        >
                            Load Example Program
                        </button>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <h2 className="text-xl font-semibold mb-2">Available Operations</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            {operations.map((op, index) => (
                                <div key={index} className="flex">
                                    <span className="font-mono font-bold mr-2">{op.command}:</span>
                                    <span>{op.description}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <h2 className="text-xl font-semibold mb-2">Example Program: Add Two Numbers</h2>
                        <p>This program adds two numbers (5 and 3) and outputs the result.</p>
                        <pre className="bg-gray-200 p-2 rounded mt-2">
                            {exampleProgram.map((card, index) => (
                                <div key={index}>{`${index + 1}: ${card}`}</div>
                            ))}
                        </pre>
                        <p className="mt-2">Click "Load Example Program" to use this example.</p>
                    </div>
                    <div className="space-y-2">
                        {cards.map((card, index) => (
                            <div key={index} className="flex items-center">
                                <span className="mr-2 font-mono">{(index + 1).toString().padStart(2, '0')}:</span>
                                <input
                                    type="text"
                                    value={card}
                                    onChange={(e) => updateCard(index, e.target.value)}
                                    className="flex-grow font-mono bg-yellow-100 border border-yellow-300 p-2 rounded uppercase"
                                    maxLength={80}
                                    style={{ direction: 'ltr', textAlign: 'left' }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="mt-4">
                        <h2 className="text-xl font-bold mb-2">Output:</h2>
                        <pre className="bg-black text-green-400 p-4 rounded font-mono whitespace-pre-wrap">
                            {output}
                        </pre>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PunchCardSimulator;